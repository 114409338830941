import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

const defaultOptions = {
    showCancelButton: true,
    confirmButtonText: 'Sí',
    cancelButtonText: 'No',
    reverseButtons: true,
    allowOutsideClick: false,
    allowEscapeKey: false
};

Vue.use(VueSweetalert2, defaultOptions);