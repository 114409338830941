import Vue from 'vue';
import VueRouter from 'vue-router';

import { userTokenUrl } from '@routes';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('accessToken');

            if (token) {
                Vue.axios.get(userTokenUrl()).then(() => {
                    next({ name: 'UserIndex', replace: true });
                }).catch(() => {
                    next();
                });
            } else {
                next();
            }
        }
    },
    {
        path: '/',
        component: () => import('../views/Base'),
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('accessToken');

            if (token) {
                Vue.axios.get(userTokenUrl()).then(() => {
                    next();
                }).catch(() => {
                    next({ name: 'Login', replace: true });
                });
            } else {
                next({ name: 'Login', replace: true });
            }
        },
        children: [
            {
                path: 'business-order',
                name: 'BusinessOrderIndex',
                component: () => import('../views/BusinessOrder/Index'),
                meta: {
                    module: 'businessOrder'
                }
            },
            {
                path: 'business-order/new',
                name: 'BusinessOrderNew',
                component: () => import('../views/BusinessOrder/Form'),
                meta: {
                    module: 'businessOrder'
                }
            },
            {
                path: 'business-order/:id/edit',
                name: 'BusinessOrderEdit',
                component: () => import('../views/BusinessOrder/Form'),
                meta: {
                    module: 'businessOrder'
                }
            },
            {
                path: 'business-order/:id/show',
                name: 'BusinessOrderShow',
                component: () => import('../views/BusinessOrder/Show'),
                meta: {
                    module: 'businessOrder'
                }
            },
            {
                path: 'user',
                name: 'UserIndex',
                component: () => import('../views/User/Index'),
                meta: {
                    module: 'user'
                }
            },
            {
                path: 'user/new',
                name: 'UserNew',
                component: () => import('../views/User/Form'),
                meta: {
                    module: 'user'
                }
            },
            {
                path: 'user/:id/edit',
                name: 'UserEdit',
                component: () => import('../views/User/Form'),
                meta: {
                    module: 'user'
                }
            },
            {
                path: 'user/:id/show',
                name: 'UserShow',
                component: () => import('../views/User/Show'),
                meta: {
                    module: 'user'
                }
            },
            {
                path: 'profile',
                name: 'Profile',
                component: () => import('../views/User/Profile'),
                meta: {
                    module: 'profile'
                }
            },
            {
                path: 'branch',
                name: 'BranchIndex',
                component: () => import('../views/Branch/Index'),
                meta: {
                    module: 'branch'
                }
            },
            {
                path: 'branch/new',
                name: 'BranchNew',
                component: () => import('../views/Branch/Form'),
                meta: {
                    module: 'branch'
                }
            },
            {
                path: 'branch/:id/edit',
                name: 'BranchEdit',
                component: () => import('../views/Branch/Form'),
                meta: {
                    module: 'branch'
                }
            },
            {
                path: 'branch/:id/show',
                name: 'BranchShow',
                component: () => import('../views/Branch/Show'),
                meta: {
                    module: 'branch'
                }
            },
            {
                path: 'vehicle',
                name: 'VehicleIndex',
                component: () => import('../views/Vehicle/Index'),
                meta: {
                    module: 'vehicle'
                }
            },
            {
                path: 'vehicle/new',
                name: 'VehicleNew',
                component: () => import('../views/Vehicle/Form'),
                meta: {
                    module: 'vehicle'
                }
            },
            {
                path: 'vehicle/:id/edit',
                name: 'VehicleEdit',
                component: () => import('../views/Vehicle/Form'),
                meta: {
                    module: 'vehicle'
                }
            },
            {
                path: 'vehicle/:id/show',
                name: 'VehicleShow',
                component: () => import('../views/Vehicle/Show'),
                meta: {
                    module: 'vehicle'
                }
            },
            {
                path: 'hotel/:id/show',
                name: 'HotelShow',
                component: () => import('../views/Hotel/Show'),
                meta: {
                    module: 'hotel'
                }
            },
            {
                path: 'hotel',
                name: 'HotelIndex',
                component: () => import('../views/Hotel/Index'),
                meta: {
                    module: 'hotel'
                }
            },
            {
                path: 'hotel/new',
                name: 'HotelNew',
                component: () => import('../views/Hotel/Form'),
                meta: {
                    module: 'hotel'
                }
            },
            {
                path: 'hotel/:id/edit',
                name: 'HotelEdit',
                component: () => import('../views/Hotel/Form'),
                meta: {
                    module: 'hotel'
                }
            },
            {
                path: 'service',
                name: 'ServiceIndex',
                component: () => import('../views/Service/Index'),
                meta: {
                    module: 'service'
                }
            },
            {
                path: 'service/new',
                name: 'ServiceNew',
                component: () => import('../views/Service/Form'),
                meta: {
                    module: 'service'
                }
            },
            {
                path: 'service/:id/edit',
                name: 'ServiceEdit',
                component: () => import('../views/Service/Form'),
                meta: {
                    module: 'service'
                }
            },
            {
                path: 'service/:id/show',
                name: 'ServiceShow',
                component: () => import('../views/Service/Show'),
                meta: {
                    module: 'service'
                }
            },

            // Customer routes
            {
                path: 'customer/',
                name: 'CustomerIndex',
                component: () => import('../views/BusinessEntity/Index'),
                meta: {
                    module: 'customer'
                }
            },
            {
                path: 'customer/new',
                name: 'CustomerNew',
                component: () => import('../views/BusinessEntity/Form'),
                meta: {
                    module: 'customer'
                }
            },
            {
                path: 'customer/:id/edit',
                name: 'CustomerEdit',
                component: () => import('../views/BusinessEntity/Form'),
                meta: {
                    module: 'customer'
                }
            },
            {
                path: 'customer/:id/show',
                name: 'CustomerShow',
                component: () => import('../views/BusinessEntity/Show'),
                meta: {
                    module: 'customer'
                }
            },

            // Supplier routes
            {
                path: 'supplier/',
                name: 'SupplierIndex',
                component: () => import('../views/BusinessEntity/Index'),
                meta: {
                    module: 'supplier'
                }
            },
            {
                path: 'supplier/new',
                name: 'SupplierNew',
                component: () => import('../views/BusinessEntity/Form'),
                meta: {
                    module: 'supplier'
                }
            },
            {
                path: 'supplier/:id/edit',
                name: 'SupplierEdit',
                component: () => import('../views/BusinessEntity/Form'),
                meta: {
                    module: 'supplier'
                }
            },
            {
                path: 'supplier/:id/show',
                name: 'SupplierShow',
                component: () => import('../views/BusinessEntity/Show'),
                meta: {
                    module: 'supplier'
                }
            },
            {
                path: 'service-order',
                name: 'ServiceOrderIndex',
                component: () => import('../views/ServiceOrder/Index'),
                meta: {
                    module: 'serviceOrder'
                }
            },
            {
                path: 'service-order/new',
                name: 'ServiceOrderNew',
                component: () => import('../views/ServiceOrder/Form'),
                meta: {
                    module: 'operations'
                }
            },
            {
                path: 'service-order/:id/show',
                name: 'ServiceOrderShow',
                component: () => import('../views/ServiceOrder/Show'),
                meta: {
                    module: 'serviceOrder'
                }
            },
            {
                path: 'account-rp',
                name: 'AccountRpIndex',
                component: () => import('../views/AccountRp/Index'),
                meta: {
                    module: 'accountRp'
                }
            },
            {
                path: 'account-rp/:id/show',
                name: 'AccountRpShow',
                component: () => import('../views/AccountRp/Show'),
                meta: {
                    module: 'accountRp'
                }
            },
            {
                path: 'payment',
                name: 'PaymentIndex',
                component: () => import('../views/Payment/Index'),
                meta: {
                    module: 'payment'
                }
            },
            {
                path: 'payment/new',
                name: 'PaymentNew',
                component: () => import('../views/Payment/Form'),
                meta: {
                    module: 'payment'
                }
            },
            {
                path: 'payment/:id/show',
                name: 'PaymentShow',
                component: () => import('../views/Payment/Show'),
                meta: {
                    module: 'payment'
                }
            },
            {
                path: 'expense',
                name: 'ExpenseIndex',
                component: () => import('../views/Expense/Index'),
                meta: {
                    module: 'expense'
                }
            },
            {
                path: 'expense/new',
                name: 'ExpenseNew',
                component: () => import('../views/Expense/Form'),
                meta: {
                    module: 'expense'
                }
            },
            {
                path: 'expense/:id/edit',
                name: 'ExpenseEdit',
                component: () => import('../views/Expense/Form'),
                meta: {
                    module: 'expense'
                }
            },
            {
                path: 'expense/:id/show',
                name: 'ExpenseShow',
                component: () => import('../views/Expense/Show'),
                meta: {
                    module: 'expense'
                }
            },
            {
                path: 'report',
                name: 'ReportIndex',
                component: () => import('../views/Report/Index'),
                meta: {
                    module: 'report'
                }
            }

        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
