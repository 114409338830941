import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vue from '../main';

import { userValidateUrl, oauthTokenUrl } from '@routes';

Vue.use(VueAxios, axios);

// Axios interceptors
let refreshingInProgress = false;
let requestQueue = [];

function processQueue(error) {
    requestQueue.forEach(promise => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve();
        }
    });

    requestQueue = [];
}

Vue.axios.interceptors.request.use(config => {
    if (config.url === oauthTokenUrl()) {
        config.headers.Authorization = `Basic ${btoa(localStorage.getItem('clientId') + ':' + localStorage.getItem('clientSecret'))}`;
    } else if (config.url !== userValidateUrl()) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }

    return config;
}, error => Promise.reject(error));

Vue.axios.interceptors.response.use(response => response, error => {
    return new Promise((resolve, reject) => {
        if (error.response.status === 401 && error.response.config.url !== userValidateUrl()) {

            const originalRequest = error.config;

            if (refreshingInProgress) {
                return new Promise((resolve, reject) => {
                    requestQueue.push({ resolve, reject });
                }).then(() => {
                    return Vue.axios.request(originalRequest).then(response => { resolve(response); });
                }).catch(error => {
                    reject(error);
                });
            }

            refreshingInProgress = true;

            refreshToken().then(response => {
                localStorage.setItem('accessToken', response.data.access_token);
                localStorage.setItem('refreshToken', response.data.refresh_token);
                // localStorage.expiresIn = response.data.expiresIn;

                processQueue();
                return Vue.axios.request(originalRequest).then(response => {
                    refreshingInProgress = false;
                    resolve(response);
                });
            }).catch(error => {
                reject(error);
            });
        } else if (error.response.status === 400 && error.response.config.url === oauthTokenUrl()) {
            logOut();
        } else {
            reject(error);
        }
    });
});

function refreshToken() {
    return new Promise((resolve, reject) => {

        let formData = new FormData();
        formData.set('grant_type', 'refresh_token');
        formData.set('refresh_token', localStorage.getItem('refreshToken'));

        axios.post(oauthTokenUrl(), formData)
            .then(response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
    });
}

function logOut() {
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userSettings');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('clientId');
    localStorage.removeItem('clientSecret');
    localStorage.removeItem('application');
    localStorage.removeItem('clientToken');
    localStorage.removeItem('bannerDate');

    if (vue.$route.name) {
        vue.$router.replace({ name: 'Login', params: vue.$route.params });
    }
}